import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Working with git is awesome. I used to just type every command by hand. I've even learned some flags to make things a bit easier, for example using `}<inlineCode parentName="p">{`-u`}</inlineCode>{` instead of writing `}<inlineCode parentName="p">{`--set-upstream`}</inlineCode>{`.
Typing full commands when you are doing one or two commits per day, it's fine. But on my new job, on this first week, I've been cloning repos, creating branches, committing and pushing quite frequently.`}</p>
    <p>{`So I decided to tick something that had been on my to-do list for ages - create short git aliases!`}</p>
    <p>{`On this article, I'll share with you the aliases that I've created, but also some others that I have been using for years and I can't live without. Hopefully, this will be useful to you.`}</p>
    <h2 {...{
      "id": "clone-and-cd-into-the-folder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#clone-and-cd-into-the-folder",
        "aria-label": "clone and cd into the folder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Clone and CD into the folder`}</h2>
    <p>{`This is one thing that I find a bit boring, cloning and then changing directory to the cloned repo. There has to be a better way to do this, well you could do something like `}<inlineCode parentName="p">{`git clone <url> && cd $_`}</inlineCode>{` but that would mean write more - yeah I'm lazy at times.`}</p>
    <p>{`I decided to go in search of a good way to deal with this and came across this `}<a parentName="p" {...{
        "href": "https://unix.stackexchange.com/a/97922"
      }}>{`stackoverflow answer`}</a>{`. It looked like it did what I wanted, after testing, it does!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gclone() {
    git clone "$1" && cd "$(basename "$1" .git)"
}
`}</code></pre>
    <p>{`Now I can run `}<inlineCode parentName="p">{`gclone <url>`}</inlineCode>{` and git will clone the repository and then change the directory into the new folder!`}</p>
    <h2 {...{
      "id": "useful-aliases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#useful-aliases",
        "aria-label": "useful aliases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Useful aliases`}</h2>
    <p>{`There are a few commands that I tend to write all the time.`}</p>
    <ul>
      <li parentName="ul">{`git commit -m`}</li>
      <li parentName="ul">{`git commit -am`}</li>
      <li parentName="ul">{`git push -u `}</li>
    </ul>
    <p>{`Since I tend to use these so often, why not just creating an alias for each?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`alias gpuo='git push -u origin'
alias gpu='git push -u'
alias gcam='git commit -am'
alias gc='git commit -m'
alias gbd='git branch -D'
`}</code></pre>
    <p>{`I've decided to create an alias for when I'm trying to push a new branch to my remote repo which is always origin. This allows me to use the command as `}<inlineCode parentName="p">{`gpuo my-feature`}</inlineCode>{` to push my-feature branch to my remote repository. I'm still trying to see if I will ever use the smaller alias `}<inlineCode parentName="p">{`gpu`}</inlineCode>{`, if I don't I will probably just replace `}<inlineCode parentName="p">{`gpu`}</inlineCode>{` with the `}<inlineCode parentName="p">{`gpuo`}</inlineCode>{` one.`}</p>
    <h2 {...{
      "id": "old-aliases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#old-aliases",
        "aria-label": "old aliases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Old aliases`}</h2>
    <p>{`I've been using these two aliases since day one. The log one shows a nice graph of the git logs, while status one shows the git status in a short way. These work great, but I still have to type `}<inlineCode parentName="p">{`git`}</inlineCode>{` first, so I decided to make them even shorter because why not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`alias gst='git status -s'
alias glog='log --oneline --decorate --all --graph'
`}</code></pre>
    <h2 {...{
      "id": "full-list-of-aliases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#full-list-of-aliases",
        "aria-label": "full list of aliases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full list of aliases`}</h2>
    <p>{`Just in case you want to copy all of these without having to click on each code block, here's the full list of my aliases. Let me know if you have any suggestion for these or if they are useful to you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`alias gpuo='git push -u origin'
alias gpu='git push -u'
alias gcam='git commit -am'
alias gc='git commit -m'
alias gbd='git branch -D'
alias gst='git status -s'
alias glog='log --oneline --decorate --all --graph'

gclone() {
    git clone "$1" && cd "$(basename "$1" .git)"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      